.services-container {
  padding-top: 50px;
  width: 80%;
  margin: 0 auto;
  height: fit-content;
  display: flex;
  flex-direction: column;
  background: transparent;
}

.services-title {
  color: #edb71e;
  font-size: 1.5rem;
  font-weight: 400;
  height: 20%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 50px;
  padding-bottom: 70px;
  /* border: 2px solid red; */
}

.services-cards {
  margin: auto;
  padding-top: 50px;
  width: 90%;
  height: fit-content;
  display: grid;
  row-gap: 35px;
  justify-items: center;
  align-items: center;
  /* padding: 10px; */
}

.card-container {
  width: 300px;
  height: 300px;
  /* border: 1px solid white; */
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  background: #edb71e;
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.card-container:hover {
  transform: translateY(-10px);
}

.card-title {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  padding-left: 20px;
  font-size: 1.3rem;
  gap: 10px;
}

.card-title p {
  font-size: 1.25rem;
  font-weight: 600;
}

.card-desc {
  color: black;
  text-align: center;
  width: 100%;
  line-height: 1.8;
  margin: 0 auto;
  height: 50%;
  text-align: center;
  font-size: large;
}

.card-desc div {
  display: flex;
  width: 100%;
  height: 23.33%;
  justify-content: flex-start;
  padding-left: 20px;
}

.card-logo {
  background-color: #dab11dca;
  border-radius: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .services-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 1200px) {
  .services-cards {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 767px) {
  .services-title {
    font-size: 1.2rem;
  }
}

@media (max-width: 545px) {
  .services-title {
    font-size: 1rem;
  }
  .card-title p {
    font-size: 0.95rem;
  }
}
