.about-container {
  width: 100%;
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #edb71e;
  transition: all 0.5s ease-in-out;
}

.about-sub {
  width: 100%;
  height: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.5s ease-in-out;
}

.about-title {
  transition: all 0.5s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.about-description div {
  text-align: center;
  line-height: 1.5;
}

.about-title span {
  font-size: 2.4rem;
  font-weight: 900;
  color: black;
  transition: all 0.5s ease-in-out;
}

.stats-container {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px;
  align-items: flex-end;
  justify-content: space-around;
  transition: all 0.5s ease-in-out;

  font-size: 20px;
  position: relative;
  bottom: -120px;
}

.stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: black;
  position: absolute;
  bottom: -50px;
  z-index: 10;
  width: 100%;
  max-width: 850px;
  margin: 0 auto;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
 
}

.stat {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  border-right: 2px solid #edb71e;
  flex: 1;
  box-sizing: border-box;
  transition: all 0.5s ease-in-out;
}

.stat:last-child {
  border-right: none;
}

.stat h3 {
  font-size: 40px;
  font-weight: 900;
  color: #edb71e;
  margin-right: 20px;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.stat p {
  color: #edb71e;
  font-weight: 300;
}

.about-description {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  width: 60%;
  margin: 0 auto;
  padding: 10px;
}
.about-description div {
  padding: 20px;
  width: 100%;
  font-size: 1.5rem;
}
.heart{
  margin-bottom: -3px;
}

@media screen and (max-width: 868px) {
  .stats-container {
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: -120px;
    transition: all 0.5s ease-in-out;
  }
  .about-title h1 {
    font-size: 2.5rem;
  }
  .about-title span {
    font-size: 1.5rem;
  }

  .about-description {
    width: 90%;
  }
  .about-description div {
    font-size: 1.2rem;
    width: 90%;
    line-height: 1.5;
  }
  .stats {
    position: static;
    flex-direction: row;
    padding: 20px;
    width: 70%;
    transition: all 0.5s ease-in-out;
  }

  .stat {
    padding: 10px;
    flex-direction: column;
    margin-bottom: 20px;
    transition: all 0.5s ease-in-out;
  }

  .stat h3 {
    font-size: 30px;
    margin-right: 0;
    margin-bottom: 10px;
    text-align: left;
    width: 100%;
  }
  .about-sub {
    height: 10%;
  }
  .stat p {
    font-size: 16px;
    text-align: left;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .about-description {
    width: 100%;
  }
  .about-description div {
    font-size: 1rem;
    width: 90%;
    line-height: 1.5;
  }
}

 
@media (max-width: 580px) {
  .about-title {
    margin-top: -70px;
    
  }
  .desc1 {
    font-size: 15px !important;
    margin-bottom: 10px;
    padding: 8px !important;
  }
  .desc2 {
    font-size: 15px !important;
    padding: 8px !important;
    margin-bottom: 100px;
  }
  
  .stat p {
    font-size: 9px;
    width: 90px;
  }
  .stat h3 {
    font-size: 18px;
  }
  .stats {
    width: 90%;
    margin-top: -30px;
  }
}

@media (max-width: 360px){
  .stats{
    flex-wrap: wrap;
  }
}