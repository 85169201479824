@import url("https://fonts.cdnfonts.com/css/gilroy-bold");

.heroContact-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.hero-contact-sub {
  width: 80%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.hero-contact-title {
  font-size: 1.3rem;
  font-family: "Gilroy-Regular", sans-serif;
  color: white;
  margin-bottom: 1rem;
  font-weight: 400;
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 1.8;
  margin-left: 20px;
}

.hero-contact-sub img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contact-img img {
  width: 400px;
  height: 400px;
  object-fit: cover;
  animation: slideAnimation 3.5s ease-in-out infinite alternate;
}

@keyframes slideAnimation {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(15px);
  }
  100% {
    transform: translateX(0);
  }
}

.contact-arrow {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  background: #edb71e;
  overflow: hidden;
}

.contact-arrow span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 600;
  color: black;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

@media (max-width: 867px) {
  .hero-contact-title {
    font-size: 0.9rem;
    line-height: 1.5;
  }

  .contact-img img {
    width: 300px;
    height: 300px;
  }
}

@media (max-width: 767px) {
  .hero-contact-title {
    font-size: 0.8rem;
    line-height: 1.5;
  }

  .contact-img img {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 500px) {
  .hero-contact-sub {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .hero-contact-sub img {
    order: 1;
    width: 250px;
    height: 250px;
  }
  .hero-contact-title {
    order: 2;
    width: 100%;
    font-size: 12px;
    margin: 0;
    margin-top: 40px;
    text-align: center;
  }
  .contact-arrow {
    bottom: 35px;
  }
}
