.TeamSection {
  width: 100%;
  padding: 25px;
}
.teamMainDiv {
  width: 100%;
  height: 500px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  margin-top: 35px;
 
}
.teamMainDiv h2 {
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  color: #edb71e;
  margin-bottom: -20px;
  
}

.mySwiper {
  width: 90%;
  height: 50%;
  background: black;
}

.team-slide {
  display: flex;
}

.teams-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}
.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  /* background-color: rgba(0, 0, 0, 0.5); */
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.custom-arrow.prev {
  left: -5px;
}

.custom-arrow.next {
  right: -8px;
}
