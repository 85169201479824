@import url("https://fonts.cdnfonts.com/css/gilroy-bold");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Gilroy-Regular', sans-serif;
                                               
}
body{
  background-color:black ;
}

::-webkit-scrollbar {
  display: none;
}

html {
  scroll-behavior: smooth;
}
.custom-arrow {
  background: none !important;
}
.custom-arrow.next {
  right: -15px !important;
}
.custom-arrow.prev {
  left: -15px !important;
}