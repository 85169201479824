footer {
  background-color: #1a1a1a;
  padding: 30px 0;
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  bottom: 0;
}
.heroF {
  color: rgb(255, 255, 255);
  padding: 30px 70px 30px 15px;
}
.heroF .container {
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
}

.flex {
  display: flex;
  
}
.container__about {
  margin-bottom: 30px;
}

.container__about p {
  margin-top: 10px;
  max-width: 300px;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
}

.container-pages {
  column-gap: 30px;
}

.container-pages ul {
  padding-top: 10px;
  list-style: none;
  line-height: 24px;
}
.container-pages ul a {
  color: inherit;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
}
.container-pages ul a:hover {
  color: #edb71e;
}
footer .by {
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
footer .by p {
  color: rgba(255, 255, 255, 0.605);
  font-size: 14px;
  width: 100%;
}
.line__separete {
  margin-top: 30px;
  width: 100%;
  height: 2px;
  background-color: #fff;
  z-index: 999;
}
.icons {
  margin-top: 20px;
  display: flex;
  column-gap: 25px;
}

.icon1 {
  width: 40px;
  height: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 20px;
  color: #fff;
  border-radius: 50%;
  outline: 2px solid #fff;
  transition-property: outline-offset, outline-color, background-color;
  transition-duration: 0.25s;
}

.icon1:hover {
  outline-offset: 4px;
}

.icon1:hover i {
  animation: shake 0.25s;
}

.icon--instagram:hover {
  background-image: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  outline-color: #a02d76;
}

.icon--twitter:hover {
  background-color: #1da1f2;
  outline-color: #1da1f2;
}

.icon--linkedin:hover {
  background-color: #0077b5;
  outline-color: #0077b5;
}

.icon--whatsapp:hover {
  background-color: #25D366;
  outline-color: #25D366;
 
}


@keyframes shake {
  10% {
    transform: rotate(15deg);
  }

  20% {
    transform: rotate(-15deg);
  }

  30% {
    transform: rotate(15deg);
  }

  40% {
    transform: rotate(-15deg);
  }
}

@media only screen and (max-width: 800px) {
  .hero {
    padding: 10px;
    display: flex;
    flex-direction: column;
  }

  .container__about h2 {
    font-size: 20px;
  }
  .container__about p {
    font-size: 12px;
  }
  .container__recentpages h2 {
    font-size: 20px;
  }
  .container-pages ul a {
    font-size: 12px;
  }
  footer .by p {
    font-size: 12px;
  }
}
@media only screen and (max-width: 400px) {
  footer .by p{
    font-size: 11.5px;
  }
}
