.project-container {
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #edb71e;
}

.project-phrase {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}

.project-phrase p {
  font-size: 3rem;
  font-weight: 600;
    
}

.project-phrase button{
    background-color: #edb71e;
    outline: none;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: none;
}


@media (max-width: 568px) {

  .project-phrase p{
    font-size: 1.5rem;
  }
  .project-icon{
  font-size: 25px;
  }
  .project-phrase button{
    font-size: 1rem;
  }
}
 