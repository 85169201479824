.newHeader {
  position: fixed;
  width: 100%;
  max-width: 100%;
  top: 0;
  left: 0;
  display: flex;
  z-index: 1000;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  padding: 15px 12%;
  transition: all 0.5s ease;
  background: transparent;
}
.scrolled {
  background: #edb71e;
}

.scrolled2 {
  background: #edb71e;
}
ul {
  list-style-type: none;
}

.newNavbar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid black;
  border-radius: 15px;
}

.newNavbar a {
  color: black;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 5px 0;
  margin: 0px 30px;
  transition: all 0.5s ease;
}

.newNavbar a:hover {
  color: white;
}
.newNavbar a.active {
  color: white;
}

.bars {
  margin-right: 10px;
  font-size: 22px;
  cursor: pointer;
  display: none;
}

.drawer {
  position: fixed;
  width: 100%;
  height: 0;
  background-color: black;
  top: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  transform: translateY(-100%);
  transition: height 0.5s ease-in-out, transform 0.5s ease-in-out; 
  overflow: hidden;
}

.drawer.open {
  height: 300px;
  transform: translateY(0%);
}


.drawer li {
  border-bottom: 1px solid #edb71e;
  width: 100%;
  height: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px !important;
  font-weight: 600;
}
.times {
  margin-right: 15px;
}
.drawer li:nth-child(1) {
  justify-content: flex-end;
  border-bottom: 1px solid #edb71e;
  height: 35%;
}

@media (max-width: 1280px) {
  .newHeader {
    padding: 14px 2%;
    transition: 0.2s;
  }
  .newNavbar a {
    padding: 5px 0;
    margin: 0px 20px;
  }
}

@media (max-width: 470px) {
  .logo {
    margin-top: 10px;
  }

  .newNavbar {
    display: none;
  }
  .bars{
    display: block;
  }
}
