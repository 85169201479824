 
.company-container {
  width: 100%;
  max-width: 1000px;
  height: auto;
  margin: auto;
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 100px;
}

.about-sectionD {
  background-color: #000;
  color: black;
  padding: 100px;
}

.about-containerD {
  max-width: 900px;
  margin: 0 auto;
  text-align: center;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

 

.card-containerD {
  display: flex;
  align-items: center;
  justify-content: center;
 
  gap: 20px;
}

.cardD {
  background-color: #edb71e;
  border-radius: 8px;
  padding: 30px;
  text-align: center;
  max-width: 375px;
  min-width: 350px;
  width: 100%;
}

 



.card-titleD {
  font-size: 28px;
  margin-bottom: 10px;
}

.card-descriptionD {
  font-size: 18px;
  line-height: 1.6;
}

@media screen and (max-width: 970px){
  .card-containerD {
    flex-wrap: wrap;
    width: 100%;
    min-width: none;
  }
}
@media screen and (max-width: 768px) {
  .about-containerD {
    padding: 20px;
  }

  .card-containerD {
    flex-wrap: wrap;
  }

  .cardD {
    max-width: 100%;
    margin-bottom: 20px;
    min-width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .company-container {
    margin-top: 50px;
    padding-bottom: 50px;
  }

  .section-headingD {
    font-size: 24px;
  }

  .cardD {
    padding: 20px;
    min-width: 250px;
    
  }

  .card-titleD {
    font-size: 20px;
  }

  .card-descriptionD {
    font-size: 16px;
  }
}
