.partners-container {
  width: 100%;
  min-height: 500px;
  color: #edb71e;
  margin-top: 50px;
}

.partners-text {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25%;
}
.partners-text h1 {
  font-size: 46px;
}

.partners-desc {
  width: 70%;
  margin: 0 auto;
  margin-top: 30px;
  text-align: center;
}
.partners-desc p {
  font-size: 24px;
  line-height: 1.5;
}

.partners-wrapper {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
  flex-direction: column;
  gap: 20px;
}

.partner-list {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 30px;
}

.partner {

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  overflow: hidden;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.partner img {
  max-width: 100%;
  width: 300px;
  height: auto;
  display: block;
}

.partner:hover {
  transform: scale(1.05);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
}

@media (max-width: 620px) {
  .partner img {
    width: 200px;
  }
 
  .partners-text h1 {
    font-size: 22px;
  }
  .partners-desc p {
    font-size: 14px;
  }
  .partners-desc {
    width: 100%;
    margin-top: 0px;
  }
  .partners-container {
    min-height: 200px;
  }
}
