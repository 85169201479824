.TeamSection {
  width: 100%;
}

.mySlider {
  position: relative;
  margin: 0 auto;
  width: 100%;
}

.custom-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1;
}

.custom-arrow.prev {
  left: 10px;
}

.custom-arrow.next {
  right: 10px;
}
.team-card-container {
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 500px;
  position: relative;
}
.team-card {
  width: 300px;
  border-radius: 10px;
  background: #edb71e;
  height: 60%;
  padding-top: 70px;
  position: relative;
  z-index: -1;
}

.team-member-image {
  width: 100%;
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: absolute;
  top: -10px;
}

.team-member-image img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}

.team-member-name {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 20%;
}
.team-member-name h3 {
  padding-bottom: 3px;
}
.team-member-name span {
  margin-bottom: -5px;
  height: 2px;
  border-radius: 5px;
  width: 60px;
  background-color: black;
}
.team-member-position {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20%;
}
.team-member-description {
  text-align: center;
  height: 60%;
  line-height: 1.4;
  width: 100%;
  margin: auto;
  padding: 0 10px;
}

.team-member-social {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;

  top: 75%;
  left: 47%;
  z-index: 1000;
}
.team-member-social a {
  background-color: white;
  padding: 10px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  cursor: pointer;
}
