.contact-form {
  height: 100%;
  z-index: 10;
  background: black;
  width: 75%;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 70px;
  flex-direction: column;
  margin-bottom: 100px;
  color: black;
}

.form-title p {
  font-size: 2.5rem;
  font-weight: 900;
  margin-bottom: 2.5rem;
  width: 100%;
  padding: 20px;
  color: #edb71e;
}

form {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 890px;
}

.form-container {
  color: black;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 80%;
  padding: 30px;
}

.line {
  width: 2px;
  height: 600px;
  background: white;
  margin: 0px 20px;
  position: absolute;
  top: 30%;
  left: 35%;
}

.form-group-title h2 {
  font-size: 2rem;
  font-weight: 900;
  margin-bottom: 1rem;
  width: 100%;
}

.form-group-title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.contact-info {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 100%;
  gap: 100px;
  padding-bottom: 50px;
}

.contact-info div {
  width: 50%;
  min-width: 150px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;
  background: #edb71e;
  border-radius: 20px;
}

.contact-info label {
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 500;
  width: 100%;
}

.form-group {
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 60%;
  background-color: #edb71e;
  color: black;
  border-radius: 15px;
  padding-bottom: 50px;
}

.input-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 10px;
}

.input-container input {
  padding: 0px 10px;
  width: 100%;
  border: none;
  outline: none;
  color: black;
  border-bottom: 2px solid black;
  font-size: 1.2rem;
  font-weight: 500;
  color: black;
  background: transparent;
}

.input-container textarea {
  padding: 10px;
  width: 100%;
  border: none;
  outline: none;
  font-size: 1.5rem;
  font-weight: 500;
  color: black;
  background: transparent;
  border: 1px solid black;
  resize: none;
  border-radius: 10px;
}

.input-container label {
  margin-bottom: 10px;
  font-weight: 500;
  color: black;
  width: 100%;
}

.form-button-container {
  width: 96%;
  margin: 0 auto;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-button-container button {
  border: 2px solid black;
  background: transparent;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-radius: 15px;
}

.form-button-container button:hover {
  background: black;
  color: white;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:checked + label {
  background-color: black;
  border-color: black;
  color: white;
}

.services-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
}

.services-container label {
  border: 2px solid black;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 18px;
  cursor: pointer;
  width: 250px;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 1024px) {
  .contact-form {
    width: 80%;
  }

  .form-title p {
    font-size: 2rem;
  }

  .form-desc {
    font-size: 1.2rem;
  }

  .form-container {
    padding: 20px;
  }
}
@media (max-width: 1520px) and (min-width: 1400px) {
 
  .services-container label{
    width: fit-content;
  }
}







@media (max-width: 1433px) and (min-width: 767px) {
  .services-container label {
    width: 45%;
  }
  form {
    min-width: 700px;
  }
}

@media (max-width: 768px) {
  .contact-form {
    width: 90%;
  }
  .contact-info div {
    width: 100%;
  }
  .form-title p {
    font-size: 1.7rem;
  }

  .form-desc {
    font-size: 1.2rem;
  }

  form {
    flex-direction: column;
    height: auto;
  }

  .line {
    display: none;
  }

  .form-container {
    height: auto;
  }

  .contact-info {
    width: 100%;
  }

  .form-group {
    width: 100%;
  }

  .input-container input,
  .input-container textarea {
    font-size: 1rem;
  }
  .input-container input:focus{
    outline: none;
    
  }

  .input-container label {
    font-size: 1rem;
  }

  .services-container label {
    width: 45%;
  }
}

@media (max-width: 480px) {
  .contact-info {
    gap: 50px;
  }
  .contact-form {
    padding: 50px;
  }

  .form-title p,
  .form-title h2 {
    font-size: 1.5rem;
  }

  .form-desc {
    font-size: 1rem;
  }

  .form-container {
    padding: 10px;
  }

  .contact-info div {
    padding: 10px;
  }

  .input-container input,
  .input-container textarea {
    font-size: 0.9rem;
  }

  .input-container label {
    font-size: 0.9rem;
  }

  .services-container label {
    font-size: 0.9em;
  }
  form {
    width: 320px;
  }

  .form-button-container {
    flex-direction: column-reverse;
  }
  .form-title p {
    width: 350px;
    font-size: 18px;
    padding: 0;
  }
  .form-button-container button {
    background-color: black;
    color: white;
  }
  .custom-notification {
    width: 440px !important;
  }
}

.contact {
  color: black !important;
  width: 100%;
  text-decoration: none;
}
