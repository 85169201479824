@import url("https://fonts.cdnfonts.com/css/gilroy-bold");
.hero {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: #edb71e;
  clip-path: polygon(0% 0%, 100% 0%, 92.7% 78.8%, 0% 100.3%);
}

.sub-hero {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 150px auto;
  position: relative;
  height: 50%;
  flex-wrap: wrap;
  
}

.hero-text {
  text-align: center;
  color: black;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  flex-direction: column;
  width: 50%;

}

.hero-text span {
  font-size: 45.2px;
  font-family: "Gilroy-Bold", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}

.hero-text p {
  padding-top: 20px;
  font-size: 24px;
  font-weight: 600;
  margin-top: 5px;
  line-height: 1.5;
  text-align: left;
  flex-direction: column;
  width: 70%;
  word-wrap: break-word;
}

.button-container {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: fit-content;
}

.button-container button {
  border: 2px solid black;
  background: transparent;
  padding: 10px 20px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 60px;
  margin-right: 25px;
  border-radius: 15px;
}

.button-container button:hover {
  background: black;
  color: white;
}
.button-container button:active{
  color: white;
}
.arrow-down {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  cursor: pointer;
}
.arrow-down div {
  border: 2px solid black;
  width: fit-content;
  padding: 20px;
  border-radius: 100%;
  position: relative;
  margin-top: -80px;
  transition: all 0.3s ease-in-out;
}

.arrow-down div:hover {
  background: black;
}
.arrow-down div:hover span {
  color: white;
}

.arrow-down span {
  transition: all 0.3s ease-in-out;
  font-size: 1.5rem;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  text-transform: uppercase;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 90%;
}

.floating-image {
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -70%);
  animation: floatAnimation 4s infinite;
  filter: drop-shadow(0 50px 10px rgba(0, 0, 0, 0.5));
  max-width: 100%;
  height: auto;
}

@keyframes floatAnimation {
  0% {
    transform: translate(-50%, -50%);
  }
  50% {
    transform: translate(-50%, -55%);
  }
  100% {
    transform: translate(-50%, -50%);
  }
}
@media (max-width: 1024px) {
  .floating-image {
    width: 400px;
    height: 400px;
  }
}

@media (max-width: 950px) {
  .hero-text {
    width: 60%;
  }
  .hero{
    clip-path: none;
  }
}

@media (max-width: 868px) {
  .hero-text span {
    font-size: 34px;
  }
  .hero-text p {
    font-size: 20px;
  }
  .floating-image {
    height: 350px;
    width: 350px;
    left: 75%;
    top: 55%;
  }
  .button-container button {
    font-size: 14px;
    color:black;
  }
}

@media (max-width: 545px) {
  .hero-text {
    top: 40%;
    width: 70%;
    height: 300px;
    gap: 20px;
  }
  .hero-text span {
    font-size: 30px;
    width: 340px;
  }
  .hero-text p {
    font-size: 20px;
    width: 300px;
  }
  .floating-image {
    width: 300px;
    height: 300px;
    left: 45%;
  }
  .hero {
    clip-path: none;
    height: 100vh;
  }
  .button-container button {
    font-size: 12px;
    width: 120px;
    padding: 7px 18px;
  }
  .image-container {
    position: relative;
    width: 100%;
  }
  .arrow-down {
    display: none;
  }
  .sub-hero {
    margin-left: 40px;
    margin-top: 100px;
    gap: 20px;

  }
  .logo{
    margin-left: 15px;
  }
}

@media  (max-width: 380px) {
  .sub-hero{
    margin-top: 80px;
  }
  .floating-image{
    width: 200px;
    height: 200px;
  }
}