.clientMainDiv {
  width: 100%;
  height: 800px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  color: #151300;
  margin-top: 50px;
}

.ClientSection {
  width: 100%;
}

.myClientSlider {
  width: 100%;
  height: fit-content;
  background: black;
}

.clients-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
}

.clients-title {
  text-align: center;
  color: #edb71e;
  font-size: 1.5rem;
  font-weight: 400;
}

.clients-title {
  color: #edb71e;
  font-size: 1.4rem;
  font-weight: 500;
  height: fit-content;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.clients-container {
  color: #151300;
  width: 100%;
  height: fit-content;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  flex-wrap: nowrap;
  padding: 50px;
}

.client-card {
  width: 370px;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #edb71e;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.client-card:hover {
  transform: translateY(-5px);
}
.client-card:hover .client-proj-image img {
  transform: scale(1.1);
}

.client-proj-image {
  width: 100%;
  height: 70%;
  object-fit: cover;
  overflow: hidden;
  position: relative;
}
.client-proj-image::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 1;
}
.client-proj-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.client-description {
  width: 100%;
  height: fit-content;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.project-title {
  width: 90%;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 10px;
  padding-top: 10px;
}

.project-title h2 {
  font-size: 1.6rem;
  font-weight: 700;
}

.project-desc {
  width: 100%;
  padding: 10px;
  height: 80%;
  text-align: center;
  line-height: 1.4;
  margin: auto;
  margin-bottom: 10px;
}
.project-button {
  width: 90%;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.project-button button {
  margin-right: 15px;
  padding: 7px;
  border: none;
  background: black;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.proj-icon {
  margin-left: 2px;
  font-size: 1rem;
  color: white;
  transition: all 0.3s ease-in-out;
}
.project-button button:hover .proj-icon {
  transform: translateX(5px);
}

@media screen and (max-width: 870px) {
  .ClientSection {
    width: 70%;
    margin: auto;
  }
  .client-card {
    margin-left: 40px;
  }
}

@media (max-width: 780px) {
  .client-card {
    margin-left: 0px;

    margin: auto;
  }
  .ClientSection {
    width: 100%;
  }
}

@media (max-width: 505px) {
  .project-title h2 {
    font-size: 20px;
  }
  .client-card {
    width: 310px;
  }
  .clients-title h1 {
    margin-top: 90px;
    font-size: 26px;
  }
  .project-desc {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }
}
@media (max-width: 420px) {
  .client-card {
    margin-left: -19px;
    width: 293px;
  }
  .project-desc {
    padding: 5px;
    line-height: 1.4;
    width: fit-content;
  }
  .client-proj-image {
    height: 200px;
  }
}
