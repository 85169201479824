.not-found {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: black;
    color: white;
    text-align: center;
  }
  
  .not-found__title {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
  }
  
  .not-found__message {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
  
  .not-found__button {
    display: inline-block;
    padding: 0.8rem 2rem;
    font-size: 1.2rem;
    color: white;
    background-color: #edb71e;
    border: none;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.3s ease;
  }
  
  .not-found__button:hover {
    background-color: white;
    color: black;
  }
  